<template>
  <div
    class="contracts-control"
  >
    <v-text-field
      v-model.lazy="controller.budget"
      label="Orçamento"
      prefix="R$"
      outlined
      hide-details
      :disabled="disabled"
      ref="budget"
      class="mb-6"
    />

    <v-text-field
      v-model.lazy="controller.impressions" 
      label="Impressões contratadas"
      outlined
      hide-details
      :disabled="disabled"
      ref="price"
      class="mb-6"
    />

    <v-menu
      v-model="view.period"
      :close-on-click="false"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="period"
          label="Data início – Data término"
          outlined
          hide-details
          :disabled="disabled"
          read-only
          ref="period"
          class="mb-6"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <div
        class="d-flex flex-column align-center white"
      >
        <v-btn
          fab
          small
          absolute
          bottom
          right
          color="success"
          class="mb-9"
          @click="view.period=false"
        >
          <v-icon>{{ icons.check }}</v-icon>
        </v-btn>
        <period-date
          :selected="controller.period"
          :min="rules.period.min"
          header
          height="360"
          :disabled="disabled"
          class="period-control"
          @update="updatePeriod"
        />
      </div>
    </v-menu>

    <v-btn
      text
      small
      :disabled="disabled||!isNew"
      color="error"
      class="mt-2"
      @click="deleteContract"
    >
      <v-icon left small>{{ icons.delete }}</v-icon>
      Remover Contrato
    </v-btn>
  </div>
</template>

<style lang="scss">


</style>

<script>

  import { 
    mdiChevronRight,
    mdiTrashCan,
    mdiCheck
  } from '@mdi/js';
  import {VMoney} from 'v-money'
  const moment = require('moment');

  export default {
    props: {
      selected: {
        type: [Number, String],
        default: null
      },
      data: {
        type: Object,
        default: () => null
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    
    data: () => ({
      icons: {
        next: mdiChevronRight,
        delete: mdiTrashCan,
        check: mdiCheck
      },
      controller: {
        id: null,
        budget: 0, 
        impressions: 0, 
        provisioned: 0, 
        price: 0, 
        period: {
          start: null,
          end: null,
        },
      },
      format: {
        budget: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
        },
      },
      rules: {
        period: {
          min: null,
          max: null,
        }
      },
      view: {
        period: false,
      }
    }),

    watch: {
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          if (!_.isNil(data)) {
            console.log('contract', data);
            const controller = _.cloneDeep(data);
            controller.budget = controller.budget.toString().replace('.', ',');
  
            _.each(controller, (field, f) => {
              this.controller[f] = _.clone(field);
            })
            if (_.has(this.$refs, 'budget')) {
              this.$refs['budget'].$refs['input'].value = controller.budget;
            }
          }
        }
      },
      controller: {
        immediate: true,
        deep: true,
        handler (data) {
          const fields = _.clone(data);
          let changed = false;
          _.each(fields, (d, f) => {
            if (f=='budget') {
              d = _.isString(d) ? parseFloat(d.replace('.', '').replace(',', '.')) : d;
            }else if (f=='impressions') {
              d = _.isString(d) ? parseInt(d) : d;
            }
            if (!_.isEqual(this.data[f], d)) console.log('changed', f, this.data[f], d)
            changed = !changed ? !_.isEqual(this.data[f], d) : changed;
          })
          if (changed) this.update()
        }
      },
    },

     computed: {
       isNew () {
        return this.data!=null&&this.data.id==null;
      },
      period () {
        const period = this.controller.period;
        return `${moment(period.start).format('DD/MM/YY')} – ${moment(period.end).format('DD/MM/YY')}`
      },
     },

    methods: {
      update () {
        let data = _.cloneDeep(this.controller);
        data.budget = _.isString(data.budget) ? parseFloat(data.budget.replace('.', '').replace(',', '.')) : data.budget;
        data.impressions = _.isString(data.impressions) ? parseInt(data.impressions) : data.impressions;
        if (!_.isNaN(data.budget)) this.$emit('update', data);
      },

      updatePeriod (period) {
        this.controller.period = _.clone(period);
      },

      deleteContract () {
        this.$emit('delete-contract', this.selected);
      },
    },

    filters: {
      formatPeriod (period) {
        return `${moment(period.start).format('DD/MM/YY')} – ${moment(period.end).format('DD/MM/YY')}`
      },
    },

    components: {
      PeriodDate: () => import('@/components/campaigns/PeriodDate'),
    },

    directives: {
      money: VMoney
    }
  }
</script>